import React, { Component } from 'react';
// import CAROUSEL_1 from '../components/carousel';
// import Footer from '../components/Footer/Footer';
import Testimonial from '../components/Testimonial';

import Header from '../components/Header/Header';

import Footer from "../components/Footer/Footer";
// import workspacepic from "../img/workspace2.jpg";
import certspic from "../img/certs.png";
import funcmovepic from "../img/funcmove.png";
import alignpic from "../img/alignment.png";


import CAROUSEL_1 from "../components/carousel";

import pic4 from "../img/Dlunge.JPG";
import pic3 from "../img/military.jpg";
import pic1 from "../img/Dlunge2.JPG";
import pic2 from "../img/Cltpic2.JPG";
import pic5 from "../img/hiking1.jpg"




class Home extends Component {

  state = {

  }

  render() {

    return (
      <>
      <Header />
      <div className="appcontent bkg">


        <div className="container topitem">
          <div className="row">
            <div className="col-12 pt-3">

              {/* <img className="workspacepic p-3 round-5" src={workspacepic} alt="workspace2"></img> */}
              <CAROUSEL_1 pics={[pic1, pic2, pic3, pic4, pic5]}/>
              <h2 className="m-4 text-center">Welcome to DC Health and Performance</h2>
              <h5 className="col col-sm pb-4 text-center">My name is Dacota and I have a passion for helping my clients reach their goals through education, commitment and hard work. I greatly enjoy teaching my clients the why behind the what so that they can truly understand my approach to improving their lives. I meet my clients where they are and proceed in a crawl, walk, run approach to progress. </h5>
            </div>
          </div>
          <div className="row">

            <div className="col-12 col-sm m-2">
              <div className="card shadow round">
                <img src={certspic} className="pb-5" id="icon1" alt="certifications"></img>
                <div className="card-body">
                  <h5 className="card-title text-center">Certifications</h5>
                  <div className="card-text ml-4 mb-4">
                    <p>Certified Personal Trainer</p>
                    <p>Certified Corrective Exercise Specialist</p>
                    <p>Certified Weight Loss Specialist</p>
                    <p>Certified Nutritional Science Specialist</p>
                    <p>Certified Senior Fitness Specialist</p>
                    <p>Certified Corrective Exercise Specialist</p>
                    {/* <a href="#" className="btn btn-primary">Learn more...</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm m-2">
              <div className="card shadow round">
                <img src={funcmovepic} className="pb-5" id="icon2" alt="functional movement"></img>
                <div className="card-body">
                  <h5 className="card-title text-center">Physical Wellness</h5>
                  <div className="card-text ml-4 mb-4">
                    <b className="pl-2">Functional Movement Analysis-</b><p> Diagnosis of musculoskeletal dysfunction and corrective recommendations</p>
                    <b className="pl-2">Body Alignment Assessment-</b><p> Proper training and education to create a strong foundation</p>
                    <b className="pl-2">Posture- Correction-</b><p> Focus on corrective movements and daily habits to improve posture and build strength</p>

                  </div>
                  {/* <a href="#" className="btn btn-primary">Read more...</a> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm m-2">
              <div className="card shadow round">
                <img src={alignpic} id="icon3" alt="alignment"></img>
                <div className="card-body">
                  <h5 className="card-title text-center">Dietary Wellness</h5>
                  <div className="card-text ml-4 mb-4">
                    <b className="pl-2">Comprehensive Nutrition Plans-</b><p> macro balancing for optimal performance in weight loss & muscle gain</p>
                    <b className="pl-2">Personal Training-</b><p> Efficient individualized training program based in scientific approach and designed to meet your goals</p>
                    <b className="pl-2">Accountability Services-</b><p> motivational support </p>

                  </div>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <Testimonial />
          </div>
        </div>
        <div className="footer">
        <Footer />
        </div>
      </div>
      </>
    );
  }

}



export default Home;

