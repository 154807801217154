import React, { Component } from 'react';
import axios from 'axios';
import { Carousel } from 'react-bootstrap';

import cleanSheets from './dataCleaner';

import './style.css';
const worksheet = '1vuFMaGEV_aDKCRCaaV5WNMGCHIdDjngqF-ZIj7JbJEY'
const googleSheetURL1 = `https://spreadsheets.google.com/feeds/cells/${worksheet}/1/public/full?alt=json`;

class Testimonial extends Component {
    state = {
        testimonials: [],
        // count: [0, 1, 2, 3, 4]
    }

    componentDidMount() {
        axios.get(googleSheetURL1).then(({ data }) => {
            // console.log(data);
            this.setState({
                testimonials: [...cleanSheets(data)]
            })
        });
    }

    renderSlides = (arr) => {

        function shuffle(array) {
            let tempArr = [...array]
            for (let i = tempArr.length - 1; i > 0; i--) {
              let j = Math.floor(Math.random() * (i + 1));
              [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
            }
            return tempArr
          }
          const shuffledArr = shuffle(arr)
          const finalArr = shuffledArr.length < 5 ? shuffledArr : shuffledArr.slice(0, 5)

        return (
            <>
            {finalArr.map((slide,i) => (
                        <Carousel.Item interval={8000} key={i}>
                            <img
                                src={"https://drive.google.com/uc?export=view&id=" + slide.image}
                                className="d-block w-100"
                                alt={`Tetimonial pic ${i}. submitted by ${slide.first_name} ${slide.last_name}`}
                                />
                            <Carousel.Caption>
                                <h3>- {slide.first_name} {slide.last_name} age: {slide.age}</h3>
                                <p>{slide.comment}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                    {/* {this.state.count.map(i => (
                        <Carousel.Item interval={8000}>
                            <img
                                src={"https://drive.google.com/uc?export=view&id=" + this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)].image}
                                className="d-block w-100"
                                alt={'pic' + i}
                                />
                            <Carousel.Caption>
                                <h3>- {this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)].first_name} {this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)].last_name} age: {this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)].age}</h3>
                                <p>{this.state.testimonials[Math.floor(Math.random() * this.state.testimonials.length)].comment}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))} */}
            </>
        )
    }

    render() {
        // console.log(this.state.testimonials);
        // const randIndex = () => Math.floor(Math.random() * this.state.testimonials.length);

        return (
            <div className="row">
                <div className="col">
                    <div className="testimonial1 shadow-lg">
                        {this.state.testimonials.length > 0 ?
                <Carousel>
                    {this.renderSlides(this.state.testimonials)}

                </Carousel>
                    : 'loading...'}
                </div>
                </div>
            </div>
        )
    }
}

export default Testimonial;