import React from 'react';
import "./style.css";
// import kaicon from '../../img/logobest32.png'

function Footer () {
    return (
      <div className="row">
        <footer className="footer col">
         <span className="text-muted col align-self-center">
            <b>DC Health & Performance © 2020 | <span className="fcredits">By:<a href="https://kickapps.org" target="_blank" rel="noopener noreferrer">KICKapps</a></span></b>
        </span>
        </footer>
      </div>
    )
  }

export default Footer;

