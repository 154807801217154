import React from 'react';
import './style.css';
import SideNav from '../Navbar/Navbar.js';


import header1 from '../../img/header800.png';
import header1b from '../../img/header750.png';
import header2 from '../../img/header1280.png';
import header3 from '../../img/header3840.png';

function Header () {
    return (
        <div className='fixed'>
            <SideNav/>
            <div className="text-center" id="banner">
            <b className="text-center overflow ml-5 text-dchealth">COVID-Safe: 1:1 nature and privacy of our space
              = a safe experience for our clients!</b>
              </div>
              <div className="row">
                
                <img className="col-sm headerpic header1" src={header1} alt="DCHealthFit"></img>
                <img className="col-sm headerpic header1b" src={header1b} alt="DCHealthFit"></img>
                <img className="col-sm headerpic header2" src={header2} alt="DCHealthFit"></img>
                <img className="col-sm headerpic header3" src={header3} alt="DCHealthFit"></img>
              </div>
            
        </div>
    )
}

export default Header;