import React from 'react';
// import { icons } from 'react-icons';
import { Sidebar, Icon, Logo, Item, LogoText } from 'react-sidebar-ui';
// import {} from 'react-icons';
import 'react-sidebar-ui/dist/index.css';

import { Link } from "react-router-dom";

import './style.css';

import logo1 from '../../img/DClogogif3.gif';



const SideNav = () => {
 
  return (
    <div>
      <Sidebar bgColor='black' isCollapsed={true}>
        <div className='overcover'>
          
          <div className='covered'>
            <div className='logotop'>

              <Logo
                image={logo1}
                imageName='logo' />
            </div>
            <LogoText className='logotext' >DCHealthFit</LogoText>
           

            <Item bgColor='black'>
              <Icon><i className="fas fa-home blackbkg" /></Icon>
              <Link className="" to="/">Home</Link>
            </Item>
            <Item bgColor='black'>
              <Icon><i className="fas fa-info blackbkg" /></Icon>
              <Link className="" to="/about">About</Link>
            </Item>
            <Item bgColor='black'>
              <Icon><i className="far fa-address-book blackbkg" /></Icon>
              <Link className="" to="/contact">Contact</Link>
            </Item>

          </div>
        </div>
      </Sidebar>
    </div>
  )
};

export default SideNav;