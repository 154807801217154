import React, { Component } from 'react';
import '../App.css'
import { Card, Accordion, Button } from 'react-bootstrap';

import Footer from "../components/Footer/Footer";

import CAROUSEL_1 from "../components/carousel";

import Header from '../components/Header/Header';

import Dfav from "../img/Dfavpictall.jpg";

import pic4 from "../img/Dfavpictall.jpg";
import pic3 from "../img/success.jpg";
import pic2 from "../img/hiking2.jpg";
import pic1 from "../img/Chinuppic.JPG"//D:\github\KICKapps\DC-Health-Fit\src\img\Chinuppic.JPG;

class About extends Component {

  render() {

    return (

      <>
      <Header />
      <div className="appcontent bkg">

        <div className="container appcontent topitem">
          <h2 className="mt-4 pt-5 mb-4 text-center">The realistic trainer for everybody else!</h2>

          <div className="carousel1 rounded shadow">
            <CAROUSEL_1 pics={[pic1, pic2, pic3, pic4]} />
          </div>

          <div class="card card1 shadow-sm mt-5 round col-8 mb-2 pb-1">
            <img src={Dfav} class="col col-sm mt-3 round" alt="..."></img>
            <div className="col col-sm">
              <h4 className="card-title">Dacota Cagle</h4>
              <h5 className="card-subtitle mb-2 text-center text-danger">A Colorado native, I was born and raised here in the beautiful Rocky Mountains.</h5>
              <p className="m-2">I challenge all my clients, regardless of their fitness level, to start by building a foundation of proper movement and functionality. I am stern with upholding my expectations but I will not let you set unrealistic goals for yourself.  When a client sets a goal, I will be right there beside them every step of the way to help them reach it! Small steps lead to big change and I cannot wait to see what you are capable of!</p>
              {/* <a href="#multiCollapseExample1" class="btn btn-primary" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Read More...</a> */}
            </div>
            <div className="pb-3">
            <Accordion>
              <Card>
                <Card.Header>
                  <div className="text-center">
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Personal Background
                  </Accordion.Toggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body> I grew up on a ranch and I love all the quintessential CO things; snowboarding, hiking and playing disc golf. I am also a bit of a nerd and I love gaming, D&D and Magic The Gathering. My incredible girlfriend and I own a beautiful home in  Lakewood with our three silly doggos. We love spending time together and traveling as much as possible!</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                <div className="text-center">
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Professional Background
                </Accordion.Toggle>
                </div>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>People always ask me why I chose to become a trainer and the truth is that I struggled with my weight as a child and teenager. I learned bad habits with food and did not know how best to take care of my physical body, which led to additional weight and low self-esteem. Eventually I took control of my body and my health. When I was a sophomore in high school my astronomy teacher taught me about weightlifting in our spare time (which was easy since I was the only one in the class because my school was so small) and with his teachings I became obsessed with weight training and personal growth. The next year I joined the army and attended basic training in the summer between my junior and senior year. The Dacota that left was not the same Dacota that came back and it showed. I became the student weight lifting coach for my high school (again, tiny school) and continued my education as far as I could before leaving again for military service upon graduation.  During my time as a heavy equipment operator in both the military and the civilian world I put myself through school to become a certified personal trainer. My interests in this field lay very heavily in corrective exercise and weight loss, because the way I see it, if we don’t focus on moving pain free and at optimal efficiency we will never be able to achieve any other goals, be it strength gain or weight loss because if we don’t get our body working and moving right FIRST we will sabotage ourselves and become grounded before we ever feel the wind under our wings.
              </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            </div>
            


          </div>

          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
    );
  }

}



export default About;

