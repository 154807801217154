import React, { Component } from 'react';
// import InstagramEmbed from 'react-instagram-embed';

import Footer from "../components/Footer/Footer";

import Header from '../components/Header/Header';

import dacotapic from "../img/dacota.jpg";

class Contact extends Component {

  render() {

    return (
      <>
      <Header />
      <div className="appcontent bkg">


      <div className="container topitem pt-5">
        <div className="row">
          <div className="col-sm text-center">
            <h2 className="ptitle text-dark pt-5">Contact Us</h2>
             <br></br>
            <h5 className="email">
              <a href="mailto:dacota@dchealthfit.com">dacota@dchealthfit.com</a>
           </h5>
              <br></br>
           <h4 className="phone">
             <a href="tel:719-859-2321">719.859.2321</a>
            </h4>
               <br></br>
              <h5>13655 W Jewell Ave<br></br><br></br>#201 Lakewood<br></br><br></br>CO 80228</h5>
         </div>
        <br></br>
        
          <div className="col-sm text-center justify-content-center">
            <img className="dacotapic shadow mt-3" src={dacotapic} alt="cali"></img>
            <h2 className="riverside pt-3">Dacota Cagle</h2>
          </div>
          <br></br>
          
        </div>  
        <div className="row">
          <div className="col mt-5">
            <iframe title="GoogleMap" className="homeframe rounded shadow" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.553769838524!2d-105.15743853462634!3d39.68225132945738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b83b133664351%3A0xe41bcad78eee151e!2s13655%20W%20Jewell%20Ave%2C%20Lakewood%2C%20CO%2080228!5e0!3m2!1sen!2sus!4v1605204266545!5m2!1sen!2sus"></iframe>
          </div> 
          </div>
      </div>
      <div className="footer">
      <Footer />
      </div>
      </div>
    </>
    );
  }

}

export default Contact;

